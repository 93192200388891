import check from '../assets/images/icons/check.png';

const MainCard = ({ product, images, generateUTMLink }) => {
  return (
    <div className="main-product-card">
      <div className="mb-2">
        <h3>{product.productName}</h3>
        <p className="product">{product.productDesc}</p>
      </div>
      <div className="row g-0">
        <div className="col-12 col-sm-6 text-start pt-3 order-2 order-sm-1">
          <p className="lead">Zakaj uporabiti {product.productName}?</p>
          <table>
            <tbody>
              <tr>
                <td>
                  <img src={check} alt="check" className="check" />
                </td>
                <td className="">{product.productInfo.property_1}</td>
              </tr>
              <tr>
                <td>
                  <img src={check} alt="check" className="check" />
                </td>
                <td className="">{product.productInfo.property_2}</td>
              </tr>
              <tr>
                <td>
                  <img src={check} alt="check" className="check" />
                </td>
                <td className="">{product.productInfo.property_3}</td>
              </tr>
            </tbody>
          </table>

          <div className="mt-3">
            <p className="product">
              {' '}
              <span className="fw-bold">Cena:</span> {product.productPrice}
            </p>
            <p className="product">
              <span className="fw-bold">Čas razvoja:</span> {product.productTimeFrame}
            </p>
          </div>
          <a href={generateUTMLink(product.productLink)} target="_blank" rel="noopener noreferrer">
            <button
              onClick={() => {
                window.plausible('CTR', {
                  props: { product: product.productName },
                });
              }}
              className="btn btn-result mt-3 mb-0 mb-md-3"
            >
              VEČ INFO
            </button>
          </a>
        </div>
        <div className="col-12 col-sm-6 p-1 p-md-2 order-1 order-sm-2">
          <img
            src={images[product.productImg]}
            alt="product"
            className="img-fluid w-100 mt-0 mt-sm-2"
          />
        </div>
      </div>
    </div>
  );
};

export default MainCard;
