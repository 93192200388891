// Import React stuff
import React from 'react';

// Import helper functions
import { importAll } from '../utils/helpers';

//Import components
import Card from '../components/Card';
import MainCard from '../components/MainCard';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../utils/animations';

const Results = ({ topMatches, data, startAgain, mobile, generateUTMLink, quizProducts }) => {
  const images = importAll(
    require.context('../assets/images/products', false, /\.(png|jpe?g|svg|gif)$/)
  );

  const mainProduct = topMatches[0];
  // Exclude the first product which is the main match
  const relatedProducts = topMatches.slice(1);

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="wrapper results d-flex"
    >
      <AnimatePresence mode="wait">
        <motion.div variants={containerVariants}>
          <motion.h2 variants={child} className="mx-3 md-mx-1">
            {data.resultPage.heading}
          </motion.h2>

          <motion.div variants={child} className="card-wrap">
            <MainCard
              product={mainProduct}
              images={images}
              mobile={mobile}
              generateUTMLink={generateUTMLink}
            />
          </motion.div>

          <motion.h2 variants={child} className="mt-0 mt-md-4 mb-2 mb-sm-0 text-mint related">
            {data.resultPage.related}
          </motion.h2>

          <div className="card-wrap row g-0">
            {relatedProducts.map((related, index) => (
              <motion.div variants={child} key={index} className="col-12 col-sm-6 pe-0 pe-sm-2">
                <Card
                  relatedName={related.productName}
                  relatedDesc={related.productDesc}
                  relatedLink={generateUTMLink(related.productLink)}
                  onClick={() => {
                    window.plausible('related', {
                      props: { related: related.productName },
                    });
                  }}
                />
              </motion.div>
            ))}
            <motion.div variants={child} className="col-12 col-sm-6 pe-0 ps-sm-2">
              <Card
                relatedName={quizProducts[12].productName}
                relatedDesc={quizProducts[12].productDesc}
                relatedLink={generateUTMLink(quizProducts[12].productLink)}
                onClick={() => {
                  window.plausible('related', {
                    props: { related: quizProducts[12].productName },
                  });
                }}
              />
            </motion.div>
          </div>

          <div className="text-center mt-2 mb-3">
            <motion.button
              onClick={() => startAgain()}
              variants={child}
              className="btn btn-outline-white"
            >
              PONOVI KVIZ
            </motion.button>
          </div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default Results;
