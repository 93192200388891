// Import React stuff
import React from 'react';

// Import animation, motion
import { motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../utils/animations';

// Import icons, images
import xIcon from '../assets/images/icons/eb-x.svg';

const Intro = ({ data, startQuiz }) => {
  return (
    <>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="wrapper intro d-flex"
      >
        <motion.div
          className="intro-content position-relative"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.h2
            variants={child}
            dangerouslySetInnerHTML={{ __html: data.introPage.heading }}
          ></motion.h2>
          <motion.div variants={child} className="intro-description">
            <p>{data.introPage.description}</p>
          </motion.div>
          <motion.div variants={child} className="intro-description text-center">
            <h3>{data.introPage.usp}</h3>
            <motion.button
              whileHover={{ y: -3, scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 200, damping: 20 }}
              onClick={startQuiz}
              className="btn btn-main mt-4"
            >
              {data.introPage.cta}
            </motion.button>
          </motion.div>

          <svg
            className="intro-img"
            xmlns="http://www.w3.org/2000/svg"
            width="776"
            height="558"
            viewBox="0 0 776 558"
            fill="none"
          >
            <defs>
              <path
                id="path1"
                d="M543.7 222.523C640.445 227.968 689.985 175.495 673.54 149.425C660.642 128.979 600.988 82.1782 459.754 96.4316C357.985 106.702 291.698 85.4674 223.016 157.465C184.652 197.682 172.579 177.685 79.431 234.816C8.51417 278.311 130.841 469.56 2.84083 509.027"
                stroke="#8EE0DE"
                strokeOpacity={0.61}
                strokeWidth={2.5}
                strokeLinecap="round"
              />
              <mask id="mask1">
                <use className="mask" xlinkHref="#path1" />
              </mask>
            </defs>
            <use className="paths" xlinkHref="#path1" mask="url(#mask1)" />
          </svg>

          <motion.img
            initial={{ opacity: 0.5, x: -100, y: -100 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            whileHover={{ x: -20, rotate: 45 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 100,
              duration: 0.7,
              delay: 0.5,
            }}
            className="x-eb-1"
            src={xIcon}
            alt="x-icon"
          />
          <motion.img
            initial={{ opacity: 0.5, x: -100, y: 100 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            whileHover={{ rotate: -340, scale: 1.3 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 70,
              duration: 0.3,
              delay: 0.5,
            }}
            className="x-eb-2"
            src={xIcon}
            alt="x-icon"
          />
          <motion.img
            initial={{ opacity: 0.5, x: 100, y: -100 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            whileHover={{ rotate: 300, scale: 1.3 }}
            transition={{
              type: 'spring',
              damping: 8,
              stiffness: 50,
              duration: 0.6,
              delay: 0.5,
            }}
            className="x-eb-3"
            src={xIcon}
            alt="x-icon"
          />
          <motion.img
            initial={{ opacity: 0.5, x: 100, y: 100 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            whileHover={{ x: 20, rotate: -45 }}
            transition={{
              type: 'spring',
              damping: 8,
              stiffness: 50,
              duration: 0.4,
              delay: 0.5,
            }}
            className="x-eb-4"
            src={xIcon}
            alt="x-icon"
          />
        </motion.div>
      </motion.div>
    </>
  );
};

export default Intro;
