import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showIntro: true,
  showLoader: false,
  showProducts: false,
  currentQuestion: 1,
  chosenProducts: [],
  chosenAnswer: [],
};

export const gameSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setShowIntro: (state, action) => {
      state.showIntro = action.payload;
    },

    setShowLoader: (state, action) => {
      state.showLoader = action.payload;
    },

    setShowProducts: (state, action) => {
      state.showProducts = action.payload;
    },

    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },

    setChosenProducts: (state, action) => {
      state.chosenProducts.push(...action.payload);
    },

    setChosenAnswer: (state, action) => {
      state.chosenAnswer.push(...action.payload);
    },

    resetGame: () => initialState,
  },
});

export const {
  setShowIntro,
  setShowLoader,
  setShowProducts,
  setCurrentQuestion,
  setChosenProducts,
  setChosenAnswer,
  resetGame,
} = gameSlice.actions;

export default gameSlice.reducer;
