// Import React stuff
import React, { useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';

// Import Redux stuff
import { useDispatch } from 'react-redux';
import { getAllUsers } from '../store/actions';
import { setToken } from '../store/adminSlice';

// Import helper functions
import { downloadFile, formatDate } from '../utils/helpers';

// Import styles, icons
import { FaRedo as Refresh } from 'react-icons/fa';

const Users = () => {
  const dispatch = useDispatch();

  const [usersData, setUsersData] = useState([]);

  const mobile = useMediaQuery('only screen and (max-width: 767px)');

  const fetchDataFromServer = async () => {
    dispatch(getAllUsers())
      .then((action) => {
        const response = action.payload;

        setUsersData(response.data);
      })
      .catch((error) => {
        console.log('Login error:', error);
      });
  };

  // Function to generate a filename with the current date
  const generateFilename = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;
    console.log(`eb_idea_kviz-data_${formattedDate}`);
    return `eb_idea_kviz-data_${formattedDate}`;
  };

  const exportToJson = (e) => {
    e.preventDefault();
    downloadFile({
      data: JSON.stringify(usersData),
      fileName: `${generateFilename()}.json`,
      fileType: 'text/json',
    });
  };

  const exportToCsv = (e) => {
    e.preventDefault();
    let headers = ['Id,Name,Email,Number,Main Result,Related Result,Answers,Conditions,Date'];

    let usersCsv = usersData.reduce((acc, user) => {
      const { id, name, email, number, result, answers, conditions, createdAt } = user;
      const formattedDate = formatDate(createdAt);
      const cleanedStr = result.replace('["', '').replace('"]', '').split('","');
      const mainMatch = cleanedStr[0].replace('Main match:', '');
      const secondMatch = cleanedStr[1].replace('Second match:', '');
      const cleanAns = answers.replace(/\[|\]|"/g, '').replace(/,/g, '  ');
      acc.push(
        [id, name, email, number, mainMatch, secondMatch, cleanAns, conditions, formattedDate].join(
          ','
        )
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: `${generateFilename()}.csv`,
      fileType: 'text/csv',
    });
  };

  const Logout = () => {
    dispatch(setToken(null));
  };

  const handleRefresh = () => {
    fetchDataFromServer(); // Fetch the latest data on refresh
  };

  return (
    <div className="user">
      <div className="px-0 px-md-5 d-flex justify-content-between">
        <button className="btn btn-white" onClick={Logout}>
          LOGOUT
        </button>
        <button className="btn btn-white" onClick={handleRefresh}>
          <Refresh />
        </button>
      </div>
      <div className="px-0 py-3 px-md-5 py-md-5">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              {mobile ? null : <th scope="col">Name</th>}
              <th scope="col">Email</th>
              <th scope="col">Number</th>
              {mobile ? null : <th scope="col">Result</th>}
              {mobile ? null : <th scope="col">Conditions</th>}
              {mobile ? null : <th scope="col">Date</th>}
            </tr>
          </thead>
          <tbody>
            {usersData.map((user) => {
              const { id, name, email, number, result, createdAt, conditions } = user;
              const formattedDate = formatDate(createdAt);
              const cleanedStr = result.replace('["', '').replace('"]', '').split('","');
              const mainMatch = cleanedStr[0].replace('Main match:', '');
              const secondMatch = cleanedStr[1].replace('Second match:', '');

              return (
                <tr key={id}>
                  <td>{id}</td>
                  {mobile ? null : <td>{name}</td>}
                  <td>{email}</td>
                  <td>{number}</td>

                  {mobile ? null : <td>{mainMatch}</td>}
                  {mobile ? null : <td>{secondMatch}</td>}
                  {mobile ? null : <td>{conditions ? 'True' : 'False'}</td>}
                  {mobile ? null : <td>{formattedDate}</td>}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between justify-content-md-center my-3">
        <button className="btn btn-mint mx-md-3" onClick={exportToJson}>
          {mobile ? 'JSON' : 'JSON Download'}
        </button>
        <button className="btn btn-pink mx-md-3" onClick={exportToCsv}>
          {mobile ? 'CSV' : 'CSV Download'}
        </button>
      </div>
    </div>
  );
};

export default Users;
