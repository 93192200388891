// Import React stuff
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import Redux stuff
import { useDispatch } from 'react-redux';
import { login } from '../store/actions';
import { setToken } from '../store/adminSlice';

// Import animations
import { motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../utils/animations';

// Import images
import loginImg from '../assets/images/icons/login.png';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Admin login
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });

  const handleLogin = async (e) => {
    e.preventDefault();

    dispatch(login(formData))
      .then((action) => {
        const response = action.payload;

        if (response && response.status === 200) {
          navigate('/users');
          dispatch(setToken(response.data.token));
        } else {
          // Handle login failure (e.g., show an error message)
          setErrors({
            username: 'Incorrect username!',
            password: 'Incorrect password!',
          });
        }
      })
      .catch((error) => {
        console.log('Login error:', error);
      });
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="login bg-transparent"
    >
      <motion.div
        className="form-wrap"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {' '}
        <motion.h1 variants={child} className="mt-6 text-center">
          Admin Login{' '}
        </motion.h1>
        <motion.div variants={child} className="my-4 text-center">
          <img src={loginImg} alt="login" />
        </motion.div>
        <motion.div variants={child} className="px-2">
          <div className="form-group">
            <input
              type="text"
              name="username"
              className={`form-control ${errors.username ? 'is-invalid' : ''}`}
              value={formData.username}
              placeholder="Username*"
              onChange={handleChange}
              required
            />
            <div className="error">{errors.username}</div>
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              className={`form-control ${errors.password ? 'is-invalid' : ''}`}
              value={formData.password}
              placeholder="Password*"
              onChange={handleChange}
              required
            />
            <div className="mt-3">
              {errors.password ? (
                <p style={{ color: '#DC3545' }} className="warning-text">
                  {errors.password}
                </p>
              ) : null}
            </div>
          </div>
        </motion.div>
        <motion.div variants={child} className="mt-2 text-center">
          <button onClick={handleLogin} className="btn btn-main">
            Login
          </button>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Login;
