import Lottie from 'lottie-react';

const Loader = ({ data }) => {
  return (
    <div className="loader-wrapper">
      <p className="loading-header px-4 px-md-0">{data.loadingPage.heading}</p>
      <Lottie
        style={{ height: '300px', width: '300px', margin: '4rem auto' }}
        animationData={{
          v: '5.5.9',
          fr: 60,
          ip: 0,
          op: 192,
          w: 600,
          h: 600,
          nm: 'Game',
          ddd: 0,
          assets: [],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: 'Layer 7/Game Outlines',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 0,
                  k: [381.167, 280.933, 0],
                  ix: 2,
                  x: "var $bm_rt;\n$bm_rt = thisComp.layer('Layer 12/Game Outlines').transform.position;",
                },
                a: { a: 0, k: [720.979, 338.808, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -6.19],
                            [6.19, 0],
                            [0, 6.19],
                            [-6.19, 0],
                          ],
                          o: [
                            [0, 6.19],
                            [-6.19, 0],
                            [0, -6.19],
                            [6.19, 0],
                          ],
                          v: [
                            [11.208, 0],
                            [-0.001, 11.208],
                            [-11.209, 0],
                            [-0.001, -11.208],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.372549019608, 0.76862745098, 0.717647058824, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [720.978, 314.615], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.152, 0.152], y: [1, 1] },
                            o: { x: [0.175, 0.175], y: [0, 0] },
                            t: 60,
                            s: [0, 0],
                          },
                          { t: 90, s: [100, 100] },
                        ],
                        ix: 3,
                      },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -6.189],
                            [6.19, 0],
                            [0, 6.191],
                            [-6.19, 0],
                          ],
                          o: [
                            [0, 6.191],
                            [-6.19, 0],
                            [0, -6.189],
                            [6.19, 0],
                          ],
                          v: [
                            [11.208, -0.001],
                            [-0.001, 11.208],
                            [-11.209, -0.001],
                            [-0.001, -11.208],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.372549019608, 0.76862745098, 0.717647058824, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [720.978, 363.003], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.159, 0.159], y: [1, 1] },
                            o: { x: [0.209, 0.209], y: [0, 0] },
                            t: 60,
                            s: [0, 0],
                          },
                          { t: 90, s: [100, 100] },
                        ],
                        ix: 3,
                      },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 2',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [6.19, 0],
                            [0, 6.19],
                            [-6.191, 0],
                            [0, -6.191],
                          ],
                          o: [
                            [-6.191, 0],
                            [0, -6.191],
                            [6.19, 0],
                            [0, 6.19],
                          ],
                          v: [
                            [0.001, 11.208],
                            [-11.208, 0.001],
                            [0.001, -11.208],
                            [11.208, 0.001],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.372549019608, 0.76862745098, 0.717647058824, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [745.171, 338.809], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.114, 0.114], y: [1, 1] },
                            o: { x: [0.17, 0.17], y: [0, 0] },
                            t: 60,
                            s: [0, 0],
                          },
                          { t: 90, s: [100, 100] },
                        ],
                        ix: 3,
                      },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 3',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [6.189, 0],
                            [0, 6.19],
                            [-6.192, 0],
                            [0, -6.191],
                          ],
                          o: [
                            [-6.192, 0],
                            [0, -6.191],
                            [6.189, 0],
                            [0, 6.19],
                          ],
                          v: [
                            [0.001, 11.208],
                            [-11.208, 0.001],
                            [0.001, -11.208],
                            [11.208, 0.001],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.372549019608, 0.76862745098, 0.717647058824, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [696.784, 338.809], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.125, 0.125], y: [1, 1] },
                            o: { x: [0.17, 0.17], y: [0, 0] },
                            t: 60,
                            s: [0, 0],
                          },
                          { t: 90, s: [100, 100] },
                        ],
                        ix: 3,
                      },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 4',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 60,
              op: 3600,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: 'Layer 8/Game Outlines',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0.167] },
                      t: 60,
                      s: [0],
                    },
                    { t: 65, s: [100] },
                  ],
                  ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 0,
                  k: [218.852, 281.153, 0],
                  ix: 2,
                  x: "var $bm_rt;\n$bm_rt = thisComp.layer('Layer 13/Game Outlines').transform.position;",
                },
                a: { a: 0, k: [558.664, 339.028, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.143, y: 1 },
                            o: { x: 0.27, y: 0 },
                            t: 60,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [1.45, 0],
                                  [0, 0],
                                  [0, 1.451],
                                  [0, 0],
                                  [-1.451, 0],
                                  [0, 0],
                                  [0, -1.451],
                                ],
                                o: [
                                  [0, 1.451],
                                  [0, 0],
                                  [-1.451, 0],
                                  [0, 0],
                                  [0, -1.451],
                                  [0, 0],
                                  [1.45, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.48, -0.005],
                                  [8.854, 2.622],
                                  [-8.935, 2.622],
                                  [-11.562, -0.005],
                                  [-11.583, -0.558],
                                  [-8.956, -3.185],
                                  [8.833, -3.185],
                                  [11.459, -0.558],
                                ],
                                c: true,
                              },
                            ],
                          },
                          {
                            t: 90,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [1.45, 0],
                                  [0, 0],
                                  [0, 1.451],
                                  [0, 0],
                                  [-1.451, 0],
                                  [0, 0],
                                  [0, -1.451],
                                ],
                                o: [
                                  [0, 1.451],
                                  [0, 0],
                                  [-1.451, 0],
                                  [0, 0],
                                  [0, -1.451],
                                  [0, 0],
                                  [1.45, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.521, 26.995],
                                  [8.895, 29.622],
                                  [-8.894, 29.622],
                                  [-11.521, 26.995],
                                  [-11.521, -26.995],
                                  [-8.894, -29.622],
                                  [8.895, -29.622],
                                  [11.521, -26.995],
                                ],
                                c: true,
                              },
                            ],
                          },
                        ],
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.372549019608, 0.76862745098, 0.717647058824, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [558.664, 339.028], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 60,
              op: 3600,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: 'Layer 9/Game Outlines',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0.167] },
                      t: 60,
                      s: [0],
                    },
                    { t: 65, s: [100] },
                  ],
                  ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 0,
                  k: [218.852, 281.153, 0],
                  ix: 2,
                  x: "var $bm_rt;\n$bm_rt = thisComp.layer('Layer 11/Game Outlines').transform.position;",
                },
                a: { a: 0, k: [558.665, 339.028, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.118, y: 1 },
                            o: { x: 0.248, y: 0 },
                            t: 60,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 1.45],
                                  [0, 0],
                                  [-1.451, 0],
                                  [0, 0],
                                  [0, -1.45],
                                  [0, 0],
                                  [1.451, 0],
                                ],
                                o: [
                                  [-1.451, 0],
                                  [0, 0],
                                  [0, -1.45],
                                  [0, 0],
                                  [1.451, 0],
                                  [0, 0],
                                  [0, 1.45],
                                  [0, 0],
                                ],
                                v: [
                                  [0.005, 11.416],
                                  [-2.622, 8.79],
                                  [-2.622, -8.999],
                                  [0.005, -11.625],
                                  [0.307, -11.625],
                                  [2.934, -8.999],
                                  [2.934, 8.79],
                                  [0.307, 11.416],
                                ],
                                c: true,
                              },
                            ],
                          },
                          {
                            t: 90,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 1.45],
                                  [0, 0],
                                  [-1.451, 0],
                                  [0, 0],
                                  [0, -1.45],
                                  [0, 0],
                                  [1.451, 0],
                                ],
                                o: [
                                  [-1.451, 0],
                                  [0, 0],
                                  [0, -1.45],
                                  [0, 0],
                                  [1.451, 0],
                                  [0, 0],
                                  [0, 1.45],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.995, 11.52],
                                  [-29.622, 8.895],
                                  [-29.622, -8.895],
                                  [-26.995, -11.521],
                                  [26.995, -11.521],
                                  [29.622, -8.895],
                                  [29.622, 8.895],
                                  [26.995, 11.52],
                                ],
                                c: true,
                              },
                            ],
                          },
                        ],
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.372549019608, 0.76862745098, 0.717647058824, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [558.665, 339.028], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 60,
              op: 3600,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: 'Layer 12/Game Outlines',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.384, y: 1 },
                      o: { x: 0.628, y: 0 },
                      t: 50,
                      s: [299.915, 281.154, 0],
                      to: [13.542, 0, 0],
                      ti: [-13.542, 0, 0],
                    },
                    { t: 90, s: [381.165, 281.154, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [720.977, 339.029, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.258, 0.258, 0.667], y: [1, 1, 1] },
                      o: { x: [0.239, 0.239, 0.333], y: [0, 0, 0] },
                      t: 25,
                      s: [0, 0, 100],
                    },
                    { t: 45, s: [100, 100, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -24.761],
                            [24.761, 0],
                            [0, 24.761],
                            [-24.761, 0],
                          ],
                          o: [
                            [0, 24.761],
                            [-24.761, 0],
                            [0, -24.761],
                            [24.761, 0],
                          ],
                          v: [
                            [44.833, 0],
                            [0, 44.833],
                            [-44.833, 0],
                            [0, -44.833],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.999999820485, 0.999999760646, 0.999999820485, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [720.977, 339.029], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 0,
              op: 3600,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: 'Layer 13/Game Outlines',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.365, y: 1 },
                      o: { x: 0.647, y: 0 },
                      t: 50,
                      s: [299.977, 281.154, 0],
                      to: [-13.521, 0, 0],
                      ti: [13.521, 0, 0],
                    },
                    { t: 90, s: [218.852, 281.154, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [558.664, 339.029, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -24.761],
                            [24.761, 0],
                            [0, 24.761],
                            [-24.761, 0],
                          ],
                          o: [
                            [0, 24.761],
                            [-24.761, 0],
                            [0, -24.761],
                            [24.761, 0],
                          ],
                          v: [
                            [44.834, 0],
                            [0.001, 44.833],
                            [-44.833, 0],
                            [0.001, -44.833],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.999999820485, 0.999999760646, 0.999999820485, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [558.664, 339.029], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 50,
              op: 3600,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 4,
              nm: 'Layer 16/Game Outlines',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0.167] },
                      t: 70,
                      s: [0],
                    },
                    { t: 73, s: [100] },
                  ],
                  ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.123, y: 1 },
                      o: { x: 0.227, y: 0 },
                      t: 67,
                      s: [369.702, 297.293, 0],
                      to: [-4.833, 6.75, 0],
                      ti: [4.833, -6.75, 0],
                    },
                    { t: 91, s: [340.702, 337.793, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [680.514, 395.668, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -8.234],
                            [8.235, 0],
                            [0, 8.236],
                            [-8.235, 0],
                          ],
                          o: [
                            [0, 8.236],
                            [-8.235, 0],
                            [0, -8.234],
                            [8.235, 0],
                          ],
                          v: [
                            [14.911, -0.001],
                            [-0.001, 14.91],
                            [-14.912, -0.001],
                            [-0.001, -14.91],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.999999820485, 0.999999760646, 0.999999820485, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [680.514, 395.668], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 2',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -13.896],
                            [13.896, 0],
                            [0, 13.897],
                            [-13.897, 0],
                          ],
                          o: [
                            [0, 13.897],
                            [-13.897, 0],
                            [0, -13.896],
                            [13.896, 0],
                          ],
                          v: [
                            [25.161, -0.001],
                            [-0.001, 25.16],
                            [-25.162, -0.001],
                            [-0.001, -25.16],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.203921568627, 0.286274509804, 0.360784313725, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [680.514, 395.668], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [110, 110], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 67,
              op: 3576,
              st: -24,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 4,
              nm: 'Layer 3/Game Outlines',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0.167] },
                      t: 70,
                      s: [0],
                    },
                    { t: 73, s: [100] },
                  ],
                  ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.103, y: 1 },
                      o: { x: 0.217, y: 0 },
                      t: 67,
                      s: [230.034, 297.793, 0],
                      to: [5, 6.667, 0],
                      ti: [-5, -6.667, 0],
                    },
                    { t: 91, s: [260.034, 337.793, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [599.847, 395.668, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -8.234],
                            [8.235, 0],
                            [0, 8.236],
                            [-8.236, 0],
                          ],
                          o: [
                            [0, 8.236],
                            [-8.236, 0],
                            [0, -8.234],
                            [8.235, 0],
                          ],
                          v: [
                            [14.911, -0.001],
                            [0, 14.91],
                            [-14.912, -0.001],
                            [0, -14.91],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.999999820485, 0.999999760646, 0.999999820485, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [599.847, 395.668], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 2',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -13.896],
                            [13.896, 0],
                            [0, 13.897],
                            [-13.897, 0],
                          ],
                          o: [
                            [0, 13.897],
                            [-13.897, 0],
                            [0, -13.896],
                            [13.896, 0],
                          ],
                          v: [
                            [25.161, -0.001],
                            [0, 25.16],
                            [-25.162, -0.001],
                            [0, -25.16],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.203921568627, 0.286274509804, 0.360784313725, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [599.847, 395.668], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [110, 110], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 67,
              op: 3576,
              st: -24,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 4,
              nm: 'Layer 10/Game Outlines',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.374, y: 1 },
                      o: { x: 0.647, y: 0 },
                      t: 50,
                      s: [299.853, 281.153, 0],
                      to: [13.552, 0, 0],
                      ti: [-13.552, 0, 0],
                    },
                    { t: 90, s: [381.165, 281.153, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [720.978, 339.028, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.25, 0.25, 0.667], y: [1, 1, 1] },
                      o: { x: [0.247, 0.247, 0.333], y: [0, 0, 0] },
                      t: 25,
                      s: [0, 0, 100],
                    },
                    { t: 45, s: [100, 100, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -34.677],
                            [34.678, 0],
                            [0, 34.678],
                            [-34.678, 0],
                          ],
                          o: [
                            [0, 34.678],
                            [-34.678, 0],
                            [0, -34.677],
                            [34.678, 0],
                          ],
                          v: [
                            [62.79, 0],
                            [0, 62.789],
                            [-62.79, 0],
                            [0, -62.789],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.203921568627, 0.286274509804, 0.360784313725, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [720.978, 339.028], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 0,
              op: 3600,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 9,
              ty: 4,
              nm: 'Layer 11/Game Outlines',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.345, y: 1 },
                      o: { x: 0.685, y: 0 },
                      t: 50,
                      s: [299.915, 281.153, 0],
                      to: [-13.51, 0, 0],
                      ti: [13.51, 0, 0],
                    },
                    { t: 90, s: [218.852, 281.153, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [558.665, 339.028, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -34.677],
                            [34.678, 0],
                            [0, 34.678],
                            [-34.677, 0],
                          ],
                          o: [
                            [0, 34.678],
                            [-34.677, 0],
                            [0, -34.677],
                            [34.678, 0],
                          ],
                          v: [
                            [62.79, 0],
                            [0, 62.789],
                            [-62.79, 0],
                            [0, -62.789],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.203921568627, 0.286274509804, 0.360784313725, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [558.665, 339.028], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 50,
              op: 3600,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 10,
              ty: 4,
              nm: 'Layer 2/Game Outlines',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.214, y: 1 },
                      o: { x: 0.215, y: 0 },
                      t: 50,
                      s: [300.01, 277.083, 0],
                      to: [0, 1.208, 0],
                      ti: [0, -1.208, 0],
                    },
                    { t: 90, s: [300.01, 284.333, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [639.822, 342.208, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 50,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.156, 49.625],
                                  [0.344, 49.708],
                                  [0.344, -49.375],
                                  [0.156, -49.458],
                                ],
                                c: true,
                              },
                            ],
                          },
                          {
                            t: 90,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [81.156, 49.542],
                                  [-81.156, 49.542],
                                  [-81.156, -49.542],
                                  [81.156, -49.542],
                                ],
                                c: true,
                              },
                            ],
                          },
                        ],
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.203921568627, 0.286274509804, 0.360784313725, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [639.822, 342.208], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 50,
              op: 3600,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 11,
              ty: 4,
              nm: 'Layer 18/Game Outlines',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0.167] },
                      t: 73,
                      s: [-112],
                    },
                    { t: 83, s: [0] },
                  ],
                  ix: 10,
                  x: 'var $bm_rt;\nvar n, n, t, t, v, amp, freq, decay;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    amp = 0.025;\n    freq = 2;\n    decay = 3;\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}',
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.833, y: 0.833 },
                      o: { x: 0.167, y: 0.167 },
                      t: 73,
                      s: [260.469, 289.014, 0],
                      to: [-7.833, 0, 0],
                      ti: [7.833, 0, 0],
                    },
                    { t: 83, s: [213.469, 289.014, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [553.281, 346.889, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                      o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                      t: 73,
                      s: [0, 0, 100],
                    },
                    { t: 78, s: [100, 100, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-65.197, 27.798],
                            [-54.413, -57.264],
                            [65.197, -23.678],
                            [13.42, 57.264],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.203921568627, 0.286274509804, 0.360784313725, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [550.781, 388.389], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -23.558],
                            [23.558, 0],
                            [0, 23.56],
                            [-23.558, 0],
                          ],
                          o: [
                            [0, 23.56],
                            [-23.558, 0],
                            [0, -23.558],
                            [23.558, 0],
                          ],
                          v: [
                            [42.656, -0.001],
                            [0, 42.656],
                            [-42.657, -0.001],
                            [0, -42.656],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.203921568627, 0.286274509804, 0.360784313725, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [527.529, 423.976], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 2',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 73,
              op: 3583,
              st: -17,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 12,
              ty: 4,
              nm: 'Layer 20/Game Outlines',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0.167] },
                      t: 73,
                      s: [112],
                    },
                    { t: 83, s: [0] },
                  ],
                  ix: 10,
                  x: 'var $bm_rt;\nvar n, n, t, t, v, amp, freq, decay;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    amp = 0.025;\n    freq = 2;\n    decay = 3;\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}',
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.833, y: 0.833 },
                      o: { x: 0.167, y: 0.167 },
                      t: 73,
                      s: [339.536, 288.375, 0],
                      to: [8.167, 0, 0],
                      ti: [-8.167, 0, 0],
                    },
                    { t: 84, s: [388.536, 288.375, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [728.348, 346.25, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                      o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                      t: 73,
                      s: [0, 0, 100],
                    },
                    { t: 78, s: [100, 100, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [65.198, 27.938],
                            [54.391, -57.404],
                            [-65.198, -23.539],
                            [-13.419, 57.404],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.203921568627, 0.286274509804, 0.360784313725, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [728.848, 388.25], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, -23.558],
                            [-23.558, 0],
                            [0, 23.56],
                            [23.559, 0],
                          ],
                          o: [
                            [0, 23.56],
                            [23.559, 0],
                            [0, -23.558],
                            [-23.558, 0],
                          ],
                          v: [
                            [-42.657, -0.001],
                            [-0.001, 42.656],
                            [42.657, -0.001],
                            [-0.001, -42.656],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.203921568627, 0.286274509804, 0.360784313725, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [752.101, 423.976], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 2',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 73,
              op: 3583,
              st: -17,
              bm: 0,
            },
          ],
          markers: [],
        }}
      />
      <p className="loading-text">{data.loadingPage.text}</p>
    </div>
  );
};

export default Loader;
