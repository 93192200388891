// Import React stuff
import React, { useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

// Import Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowIntro,
  setShowLoader,
  setShowProducts,
  setCurrentQuestion,
  setChosenAnswer,
  setChosenProducts,
  resetGame,
} from './store/gameSlice';
import { setUser, setShowOptin, resetUser } from './store/userSlice';
import { createUser } from './store/actions';

// Import components, views
import Intro from './views/Intro';
import Optin from './views/Optin';
import Results from './views/Results';
import Questions from './views/Questions';
import Login from './views/Login';
import Users from './views/Users';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';

// Import helper functions
import {
  isValidEmail,
  validateForm,
  showOverlay,
  hideOverlay,
  triggerEmojiExplosion,
} from './utils/helpers';

// Import styles, icons, images
import './assets/styles/style.scss';

// Import data
import quizData from './data/apiText.json';
import quizQuestions from './data/apiQuestions.json';
import quizProducts from './data/apiProducts.json';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const showIntro = useSelector((state) => state.games.showIntro);
  const showLoader = useSelector((state) => state.games.showLoader);
  const showProducts = useSelector((state) => state.games.showProducts);
  const currentQuestion = useSelector((state) => state.games.currentQuestion);
  const chosenProducts = useSelector((state) => state.games.chosenProducts);
  const chosenAnswers = useSelector((state) => state.games.chosenAnswer);

  const showOptin = useSelector((state) => state.users.showOptin);
  const user = useSelector((state) => state.users.user);

  const token = useSelector((state) => state.admins.token);

  const numberOfQuestions = quizQuestions.content.length;

  // Optin form
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    conditions: false,
  });

  // Validate email
  const [unvalidEmail, setUnvalidEmail] = useState(false);

  // Form errors
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    conditions: false,
  });

  // Device viewport
  const mobile = useMediaQuery('only screen and (max-width: 767px)');

  // Move from intro page to quiz
  const startQuiz = () => {
    dispatch(setShowIntro(false));
    dispatch(setShowLoader(false));
    dispatch(setShowProducts(false));
    dispatch(setCurrentQuestion(1));
    dispatch(setChosenAnswer([]));
    dispatch(setChosenProducts([]));
    window.plausible('start');
  };

  // Quiz answer click
  const handleAnswerOptionClick = (path, productTags, answerText, answerEmoji, index) => {
    window.scrollTo(0, 0);

    const nextQuestion = path;

    // Add product to array based on chosen answer
    dispatch(setChosenProducts([productTags]));

    // // Add answer text to array
    dispatch(setChosenAnswer([answerText]));

    // Emoji explosion
    if (answerEmoji) {
      showOverlay(); // Show the explosion overlay
      const element = document.querySelector(`#answer-${index}`);
      const size = mobile ? 20 : 40;
      const count = mobile ? 30 : 50;
      triggerEmojiExplosion(element, answerEmoji, size, count);
    }

    // Custom properties for plausible tracking
    const customProperties = {
      questionNumber: currentQuestion,
      answerText: answerText,
    };

    // Track the answer using plausible with custom properties
    window.plausible('selected_answers', { props: customProperties });

    // Check whether next question exists otherwise show optin/results
    if (nextQuestion <= quizQuestions.content.length) {
      setTimeout(() => {
        hideOverlay(); // Hide the explosion overlay
        dispatch(setCurrentQuestion(nextQuestion));
      }, 500); //1000
    } else {
      setTimeout(() => {
        hideOverlay(); // Hide the explosion overlay
        dispatch(setShowProducts(true));
        dispatch(setShowOptin(true));
        dispatch(setShowLoader(true));
        if (user) {
          setTimeout(() => {
            setTimeout(() => {
              dispatch(setShowLoader(false));
              dispatch(setShowOptin(false));
            }, 4000);
          }, 1000);
        } else {
          setTimeout(() => {
            setTimeout(() => {
              dispatch(setShowLoader(false));
            }, 4000);
          }, 1000);
        }
      }, 1000);
    }
  };

  // Find object of current question
  const filterCurrentQuestion = quizQuestions.content.find((obj) => obj.id === currentQuestion);

  const filterProducts = (chosenProducts, quizProducts) => {
    // Filter out products based on chosen tag
    let filteredProducts = quizProducts;

    if (chosenProducts.includes('q1a2')) {
      //Marketing
      filteredProducts = filteredProducts.filter((product) => {
        return (
          product.productTags.includes('q1a2') ||
          (product.productTags.includes('q1a1') && product.productTags.includes('q1a2'))
        );
      });
    } else if (chosenProducts.includes('q1a1')) {
      // Turizem
      filteredProducts = filteredProducts.filter((product) => {
        return (
          product.productTags.includes('q1a1') ||
          (product.productTags.includes('q1a1') && product.productTags.includes('q1a2'))
        );
      });
    }

    // Log filtered products
    //console.log('Filtered Products:', filteredProducts);

    // Check the selected tags and productTags match
    filteredProducts.forEach((product) => {
      product.score = chosenProducts.reduce((score, tag) => {
        if (product.productTags.includes(tag)) {
          score++;
        }
        return score;
      }, 0);
    });

    // Sort filtered products by score in descending order
    filteredProducts.sort((a, b) => b.score - a.score);

    return filteredProducts;
  };

  // Call the function with chosenProducts and quizProducts
  const filteredMatches = filterProducts(chosenProducts, quizProducts);

  // Get the top 3 matches
  const topMatches = filteredMatches.slice(0, 2);

  // Save result into DB
  const results = JSON.stringify([
    `Main match: ${topMatches[0].productName} - score: ${topMatches[0].score}`,
    `Second match: ${topMatches[1].productName} - score: ${topMatches[1].score}`,
  ]);

  // Submit optin
  const handleShowResults = async () => {
    // Perform form validation
    const formErrors = validateForm(formData);

    if (Object.keys(formErrors).length > 0) {
      setFormErrors(formErrors);
    } else {
      const isEmailValid = isValidEmail(formData.email);
      if (formData.email.trim() !== '' && !isEmailValid) {
        setUnvalidEmail(true);
      } else {
        // Save the form data or dispatch an action
        dispatch(
          createUser({
            formData,
            answers: JSON.stringify(chosenAnswers),
            result: results,
            mainResultId: topMatches[0].productId,
            relatedResultId: topMatches[1].productId,
          })
        )
          .then((action) => {
            const response = action.payload;
            if (response && response.status === 201) {
              // User created successfully
              setTimeout(() => {
                dispatch(setUser(true));
                dispatch(setShowOptin(false));
                window.plausible('result');
                window.plausible('submit');
              }, 1000); //1000
            }
          })
          .catch((error) => {
            // Handle error
            console.log('Error:', error);
          });
      }
    }
  };

  // const handleShowResultsGuest = () => {
  //   setTimeout(() => {
  //     dispatch(setShowOptin(false));
  //     window.plausible('result');
  //   }, 1000); //1000
  // };

  // Utm builder
  function buildUTMUrl(url, source, medium, campaign) {
    const utmParams = new URLSearchParams({
      utm_source: source,
      utm_medium: medium,
      utm_campaign: campaign,
    });

    return `${url}?${utmParams.toString()}`;
  }

  const generateUTMLink = (link) => {
    const utmParams = {
      source: 'https://kviz.escapebox.si/',
      medium: 'email',
      campaign: 'Escapebox recommendation quiz',
    };

    const utmLink = buildUTMUrl(link, utmParams.source, utmParams.medium, utmParams.campaign);

    return utmLink;
  };

  // Play quiz again
  const startAgain = () => {
    dispatch(setShowOptin(false));
    // Reset game but not the user
    setTimeout(() => {
      dispatch(resetGame());
    }, 1000);
  };

  // Clear local storage
  function clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(resetGame());
    dispatch(resetUser());
  }

  window.addEventListener('beforeunload', function (event) {
    event.preventDefault();
    clearStorage();
    event.returnValue = '';
  });

  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.5 }}
      transition={{ duration: 0.3 }}
      className="quiz"
    >
      <div className="label w-100">
        <p>{quizData.label}</p>
      </div>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <>
                {showProducts ? (
                  showOptin ? (
                    <Optin
                      data={quizData}
                      formData={formData}
                      showLoader={showLoader}
                      handleShowResults={handleShowResults}
                      // handleShowResultsGuest={handleShowResultsGuest}
                      setFormData={setFormData}
                      setFormErrors={setFormErrors}
                      formErrors={formErrors}
                      setUnvalidEmail={setUnvalidEmail}
                      unvalidEmail={unvalidEmail}
                      isValidEmail={isValidEmail}
                    />
                  ) : (
                    <Results
                      topMatches={topMatches}
                      mobile={mobile}
                      startAgain={startAgain}
                      data={quizData}
                      generateUTMLink={generateUTMLink}
                      quizProducts={quizProducts}
                    />
                  )
                ) : showIntro ? (
                  <Intro startQuiz={startQuiz} data={quizData} />
                ) : (
                  <Questions
                    filterCurrentQuestion={filterCurrentQuestion}
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                    handleAnswerOptionClick={handleAnswerOptionClick}
                    numberOfQuestions={numberOfQuestions}
                  />
                )}
              </>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/users" element={token ? <Users /> : <Navigate to="/" />} />
        </Routes>
      </AnimatePresence>
    </motion.div>
  );
}

export default App;
