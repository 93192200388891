// Import React stuff
import React, { useState } from 'react';

// Import redux stuff
import { useDispatch } from 'react-redux';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../utils/animations';

// Import icons, images
import xIcon from '../assets/images/icons/eb-x.svg';

// Import components
import Pagination from '../components/Pagination';

const Questions = ({
  currentQuestion,
  filterCurrentQuestion,
  handleAnswerOptionClick,
  setCurrentQuestion,
  numberOfQuestions,
}) => {
  const dispatch = useDispatch();

  const [animateAnswer, setAnimateAnswer] = useState(false);

  return (
    <>
      <div id="explosion" className="explosion-overlay"></div>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="wrapper questions d-flex position-relative"
      >
        <motion.img variants={child} className="x-eb-1" src={xIcon} alt="x-icon" />
        <motion.img variants={child} className="x-eb-2" src={xIcon} alt="x-icon" />
        <motion.img variants={child} className="x-eb-3" src={xIcon} alt="x-icon" />
        <motion.img variants={child} className="x-eb-4" src={xIcon} alt="x-icon" />

        <div className="question-pagination">
          <Pagination currentQuestion={currentQuestion} numberOfQuestions={numberOfQuestions} />
        </div>

        <div className="question-content">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentQuestion}
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              onAnimationStart={() => {
                setAnimateAnswer(true);
              }}
              onAnimationComplete={() => {
                setAnimateAnswer(false);
              }}
              className="d-flex align-items-center flex-column"
            >
              <div className="pt-5 pt-md-0">
                <div className="lead mb-3">
                  <motion.p variants={child} className="question-text fw-bold mb-1 py-2 px-2">
                    {filterCurrentQuestion.questionText}
                  </motion.p>
                </div>
                <div
                  className={`row g-0 ${
                    filterCurrentQuestion.type === 'text-img'
                      ? 'image-question-answer-wrapper'
                      : 'text-question-answer-wrapper'
                  }`}
                >
                  {filterCurrentQuestion.answerOptions.map((answerOption, index) => (
                    <motion.div
                      variants={child}
                      key={index}
                      className={` ${
                        answerOption.answerImg
                          ? 'col-6 col-sm image-answer-wrapper text-center'
                          : 'col-6 text-answer-wrapper text-center'
                      }`}
                      style={{
                        pointerEvents: animateAnswer ? 'none' : 'auto',
                      }}
                    >
                      <button
                        id={`answer-${index}`}
                        className={`btn btn-question ${
                          answerOption.answerImg ? 'image-answer' : 'text-answer'
                        }`}
                        onClick={() => {
                          handleAnswerOptionClick(
                            answerOption.path,
                            answerOption.productTags,
                            answerOption.answerText,
                            answerOption.answerEmoji,
                            index
                          );
                        }}
                      >
                        {/* Show image and text if question answerImg true */}
                        {answerOption.answerImg ? (
                          <>
                            <img
                              src={require(`../assets/images/questions/${answerOption.answerImg}`)}
                              alt={answerOption.answerImg}
                            />
                            <span className="mt-3">{answerOption.answerText}</span>
                            <span className="answer-text-small">{answerOption.answerText2}</span>
                          </>
                        ) : (
                          <>
                            <span>{answerOption.answerText}</span>
                          </>
                        )}
                      </button>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
        <motion.div variants={child} className="back-wrapper">
          {currentQuestion > 1 && (
            <button
              className="btn btn-back"
              onClick={() => dispatch(setCurrentQuestion(currentQuestion - 1))}
            >
              <svg
                width="20"
                height="17"
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 8.46912C19.9994 7.37608 19.1118 6.49048 18.0188 6.48919L6.76216 6.49047L9.80495 3.44705C10.6289 2.62245 10.6713 1.32712 9.89807 0.552617C9.12421 -0.218675 7.82887 -0.178216 7.00363 0.646379L0.648341 7.00296C-0.178181 7.82691 -0.219925 9.12289 0.554578 9.89675C0.575771 9.91537 0.598248 9.9295 0.618798 9.94812C0.645129 9.97574 0.663753 10.0085 0.691368 10.0374L6.96189 16.3066C7.77621 17.1197 9.06191 17.1518 9.83513 16.3779C10.609 15.6041 10.5756 14.3177 9.76384 13.5034L6.71014 10.4503L18.0188 10.4503C19.1131 10.451 20 9.56473 20 8.46912Z"
                  fill="white"
                />
              </svg>
            </button>
          )}
        </motion.div>
      </motion.div>
    </>
  );
};

export default Questions;
