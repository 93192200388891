// Import React stuff
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

// Import Redux stuff
import { Provider } from 'react-redux';
import store from './store/store';

// Custom scrollbar
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars } from 'overlayscrollbars';

// Import App
import App from './App';

// Initialize OverlayScrollbars on the body element
OverlayScrollbars(document.body, {
  // options here
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
