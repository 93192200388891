import { emojisplosion } from 'emojisplosion';

export function importAll(r) {
  let images = {};
  r.keys().map((item) => {
    return (images[item.replace('./', '')] = r(item));
  });
  return images;
}

// Email validation
export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/u;
  return emailRegex.test(email);
};

// Form validation
export const validateForm = (form) => {
  const formErrors = {};

  if (form.name.trim() === '') {
    formErrors.name = true;
  }

  if (form.email.trim() === '') {
    formErrors.email = true;
  }

  if (!form.conditions) {
    formErrors.conditions = true;
  }

  return formErrors;
};

// Emoji explosion
var cumulativeOffset = function (element) {
  var top = 0,
    left = 0;
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top: top,
    left: left,
  };
};

export const triggerEmojiExplosion = (element, emoji, count, size) => {
  emojisplosion({
    emojis: emoji,
    container: document.getElementById('explosion'),
    emojiCount: count,
    fontSize: size,
    physics: {
      gravity: 0.25,
      initialVelocities: {
        rotation: {
          max: 7,
          min: -7,
        },
        y: {
          max: 20,
          min: -20,
        },
        x: {
          max: 20,
          min: -20,
        },
      },
      rotationDecelaration: 1.01,
    },
    position() {
      const offset = cumulativeOffset(element);

      return {
        x: offset.left + element.clientWidth / 2,
        y: offset.top + element.clientHeight / 2,
      };
    },
  });
};

// Explosion overlay
export const showOverlay = () => {
  const element = document.getElementById('explosion');
  if (element) {
    element.style.display = 'block';
  }
};

export const hideOverlay = () => {
  const element = document.getElementById('explosion');
  if (element) {
    element.style.display = 'none';
  }
};

// Format date
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

// Download file
export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};
