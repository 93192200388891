// Import React stuff
import React from 'react';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../utils/animations';

//Import components
import Loader from '../components/Loader';

// Import icons, images
import xIcon from '../assets/images/icons/eb-x.svg';

const Optin = ({
  data,
  formData,
  showLoader,
  handleShowResults,
  handleShowResultsGuest,
  setFormData,
  formErrors,
  setFormErrors,
  unvalidEmail,
  isValidEmail,
  setUnvalidEmail,
}) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));

    // Clear the corresponding error when the user starts typing or checks the checkbox
    if (name === 'name' && formErrors.name) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: false,
      }));
    }

    if (name === 'email') {
      if (formErrors.email) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: false,
        }));
      }

      // Check if the email is valid
      const isEmailValid = isValidEmail(value);
      setUnvalidEmail(!isEmailValid);
    }

    if (name === 'conditions' && formErrors.conditions) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        conditions: false,
      }));
    }
  };
  return (
    <>
      {showLoader ? (
        <motion.div
          variants={fadeInOut}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={`wrapper d-flex justify-content-center align-items-center`}
        >
          <div className="h-100 w-100">
            <Loader data={data} />
          </div>
        </motion.div>
      ) : (
        <motion.div
          variants={fadeInOut}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="wrapper optin d-flex"
        >
          <img className="x-eb-1" src={xIcon} alt="x-icon" />
          <img className="x-eb-2" src={xIcon} alt="x-icon" />
          <img className="x-eb-3" src={xIcon} alt="x-icon" />
          <img className="x-eb-4" src={xIcon} alt="x-icon" />
          <AnimatePresence mode="wait">
            <motion.div variants={containerVariants} initial="hidden" animate="visible" exit="exit">
              <motion.h2 variants={child}>{data.optinPage.heading}</motion.h2>
              <motion.div variants={child} className="mt-4 mb-5">
                <p dangerouslySetInnerHTML={{ __html: data.optinPage.description1 }}></p>
                {/* <p>
                  <b>{data.optinPage.description2}</b>
                </p> */}
              </motion.div>
              <motion.div variants={child} className="optin-form">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                    name="name"
                    value={formData.name}
                    placeholder="Tvoje ime*"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className={`form-control ${
                      formErrors.email || unvalidEmail ? 'is-invalid' : ''
                    }`}
                    name="email"
                    value={formData.email}
                    placeholder="Službeni elektronski naslov*"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    className={`form-control`}
                    name="number"
                    value={formData.number}
                    placeholder="Telefonska številka"
                    onChange={handleChange}
                  />
                  <div className="mt-3">
                    {formErrors.name || formErrors.email ? (
                      <p style={{ color: '#DC3545' }} className="warning-text">
                        Vnesi manjkajoče podatke.
                      </p>
                    ) : unvalidEmail ? (
                      <p style={{ color: '#DC3545' }} className="warning-text">
                        Email ni veljaven.
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-3">
                  <div className="form-check d-flex align-items-center">
                    <input
                      className={`form-check-input ${formErrors.conditions ? 'is-invalid' : ''}`}
                      type="checkbox"
                      name="conditions"
                      checked={formData.conditions}
                      id="conditions-check"
                      onChange={handleChange}
                      required
                    />
                    <label className="form-check-label ms-2" htmlFor="conditions-check">
                      Strinjam se s{' '}
                      <a href={data.optinPage.pravila} target="_blank" rel="noreferrer noopener">
                        pravili in pogoji
                      </a>{' '}
                      podjetja Escapebox.
                    </label>
                  </div>
                  <div className="mt-3">
                    {formErrors.conditions ? (
                      <p style={{ color: '#DC3545' }} className="warning-text">
                        Za nadaljevanje sprejmi pogoje.
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-3">
                  <button onClick={handleShowResults} className="btn btn-main optin-btn w-100">
                    {data.optinPage.cta}
                  </button>
                  {/* <button onClick={handleShowResultsGuest} className="btn guest-btn w-100">
                    {data.optinPage.ctaGuest}
                  </button> */}
                </div>
              </motion.div>
            </motion.div>
          </AnimatePresence>
        </motion.div>
      )}
    </>
  );
};

export default Optin;
