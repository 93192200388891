// Import Redux stuff
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import thunk from 'redux-thunk';

// Import reducers
import gameSlice from './gameSlice';
import userSlice from './userSlice';
import adminSlice from './adminSlice';

const reducers = combineReducers({
  games: gameSlice,
  users: userSlice,
  admins: adminSlice,
});

const store = configureStore({
  reducer: reducers,

  middleware: (getdefaultMiddleware) =>
    getdefaultMiddleware({
      serializableCheck: false,
    }).concat([thunk]),
});

export default store;
