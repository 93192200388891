const Card = ({ relatedName, relatedDesc, relatedLink, onClick }) => {
  return (
    <div className="card my-2 my-sm-3 text-start">
      <div>
        <h1>{relatedName}</h1>
        <p>{relatedDesc}</p>
      </div>
      <a href={relatedLink} target="_blank" rel="noopener noreferrer">
        <button onClick={onClick} className="btn btn-outline-violet">
          VEČ INFO
        </button>
      </a>
    </div>
  );
};

export default Card;
