// Import animation, motion
import { motion } from 'framer-motion';

const Pagination = ({ currentQuestion, numberOfQuestions }) => {
  const getBulletClassName = (questionIndex) => {
    if (questionIndex < currentQuestion) {
      return 'bullet previous';
    } else if (questionIndex === currentQuestion) {
      return 'bullet active';
    } else {
      return 'bullet';
    }
  };
  return (
    <div className="pagination">
      <div className="line"></div>
      {[...Array(numberOfQuestions)].map((_, index) => (
        <motion.div
          key={index + 1}
          className={`bullet bullet-${index + 1} ${getBulletClassName(index + 1)}`}
          style={{ left: `${index * 32}px` }}
        ></motion.div>
      ))}
    </div>
  );
};

export default Pagination;
