import { createSlice } from '@reduxjs/toolkit';

// Define an initial state for the admin authentication
const initialState = {
  token: null,
};

// Create a slice to handle admin authentication
const adminSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setToken } = adminSlice.actions;

export default adminSlice.reducer;
