import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../config/axios';

// Save user-game
export const createUser = createAsyncThunk(
  'users/createUser',
  async ({ formData, answers, result, mainResultId, relatedResultId }, { rejectWithValue }) => {
    try {
      const response = await API.post('/', {
        ...formData,
        answers,
        result,
        mainResultId,
        relatedResultId,
      });
      //console.log(response);
      return response;
    } catch (error) {
      //console.log(error.response);
      return rejectWithValue(error.response);
    }
  }
);

// Get all existing users
export const getAllUsers = createAsyncThunk('users/getAllUsers', async (rejectWithValue) => {
  try {
    const response = await API.get('/users');
    console.log(response);
    return response;
  } catch (error) {
    console.log(error.response);
    return rejectWithValue(error.response);
  }
});

// Login
export const login = createAsyncThunk('admin/login', async (formData, { rejectWithValue }) => {
  try {
    const response = await API.post('/admin', formData);

    return response;
  } catch (error) {
    console.error('Login error:', error);
    return rejectWithValue(error.response.data);
  }
});
